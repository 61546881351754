import QRCode from 'qrcode';

var weixinPageMask = null;
var weixinModalBody = null;
var weixinCloseModalBtn = null;
var weixinQrcodeDiv = null;

$(function(){

    $("div.qq-share").click(function(){
        shareQQ('', '', '', '');
    });

    $('div.qqzone-share').click(function(){
        qqZoneShare('', '');
    });

    $('div.weibo-share').click(function(){
        sinaWeiBoShare('', '');
    });

    $('div.baidu-share').click(function(){
        tiebaShare('', '');
    });

    $('div.weixin-share').click(function(){
        weixinShare();
    });

});

var shareSource = '财库网';

function shareQQ(shareUrl, shareTitle, shareSummary, sharePic){
    var shareUrl = shareUrl || document.location.href;

    var p = {
        // 分享的url站内 (将所有的http链接转换成https链接)
        url: shareUrl.replace(/^http:\/\//i, 'https://'),
        // 分享原因
        desc: '',
        // 分享的内容标题(可选)
        title: shareTitle || document.title,
        // 分享摘要(可选)
        summary: shareSummary,
        // 分享图片(可选)
        pics: sharePic,
        // 视频地址(可选)
        flash: '',
        // 分享来源(可选) 如:QQ分享
        site: shareSource,
        style: '201',
        width: 32,
        height: 32
    }

    var s = [];
    for(var i in p){
        s.push(i + '=' + encodeURIComponent(p[i] || ''));
    }

	var url = "http://connect.qq.com/widget/shareqq/index.html?" + s.join('&');
	window.open(url, "_blank");
}

function qqZoneShare(shareUrl, title){
    var p = {
        url: shareUrl || document.location.href,
        // 是否显示分享总数,显示: '1', 不显示: '0'
        showcount: '1',
        // 分享原因
        desc: '',
        // 分享摘要
        summary: '',
        // 分享主题
        title: title || document.title,
        // 分享来源
        site: shareSource,
        // 图片url
        prics: '',
        width: 98,
        height: 22
    }

    var s = [];

    for(var i in p){
        s.push(i + '=' + encodeURIComponent(p[i] || ''));
    }

    var url = "http://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?" + s.join('&');
    window.open(url);
}

function sinaWeiBoShare(shareUrl, title){
    var p = {
        // 表示是否显示当前页面被分享次数(1 显示)(可选， 允许为空)
        count: '1',
        // 将页面地址转成短域名，并显示在内容文字的后面.(可选， 允许为空)
        url: shareUrl || document.location.href,
        // 用于发布微博的来源显示，为空则分享的内容来源会显示来自互联网。(可选，允许为空)
        appkey: shareSource,
        // 分享时所示的文字内容，为空则自动抓取分享页面的title值(可选，允许为空)
        title: title || document.title,
        // 自定义图片地址，作为微博配图(可选，允许为空)
        pic: '',
        // 转发时会@相关的微博账号(可选，允许为空)
        language: 'zh_cn'
    };

    var s = [];

    for(var i in p){
        s.push(i + '=' + encodeURIComponent(p[i] || ''));
    }

    var url = "http://service.weibo.com/share/share.php?" + s.join('&');

    window.open(url);
}

function tiebaShare(shareUrl, title){
    var p = {
        // 分享的链接
        url: shareUrl || document.location,
        // 分享的标题
        title: title || document.title,
        // 分享的图片
        pic: '',
        desc: ''
    };

    var s = [];

    for(var i in p){
        s.push(i + '=' + p[i] || '');
    }

    var url = "http://tieba.baidu.com/f/commit/share/openShareApi?" + s.join('&');

    window.open(url, '_blank');
}

function weixinShare(){
    weixinQrcodeDiv = document.getElementById('urlShareForWeixin');

    if(weixinQrcodeDiv == null){
        weixinshareInit();
    }

    var url = document.location.href;

    weixinQrcodeDiv.innerHTML = '';

    const canvas = document.createElement('canvas');
    canvas.width = '240px';
    canvas.height = '240px';
    weixinQrcodeDiv.appendChild(canvas)

    QRCode.toCanvas(canvas, url, {
        width: 200,
        margin: 200,
        color: {
            dark: '#000000',
            light: '#0000'
        }
    });

    weixinModalBody.style.display = (weixinModalBody.style.display == "block")? "none" : "block";
    weixinCloseModalBtn.style.display = (weixinCloseModalBtn.style.display == "block")? "none" : "block";
    weixinPageMask.style.visibility = (weixinPageMask.style.visibility == "visible")? "hidden" : "visible";
    document.body.parentNode.style.overflow = 'hidden';

}

function weixinshareInit(){
    weixinPageMask = document.createElement('div');
    weixinPageMask.style.visibility = 'hidden';
    weixinPageMask.style.position = 'absolute';
    weixinPageMask.style.left = '0px';
    weixinPageMask.style.top = '0px';
    weixinPageMask.style.width = '100%';
    weixinPageMask.style.height = '100%';
    weixinPageMask.style.textAlign = 'center';
    weixinPageMask.style.zIndex = 1100;
    weixinPageMask.style.background = '#333';
    weixinPageMask.style.opacity = 0.6;
    weixinPageMask.setAttribute('id', 'weixinPageMask');

    weixinModalBody = document.createElement('div');
    weixinModalBody.style.background = 'white';
    weixinModalBody.style.width = '300px !important';
    weixinModalBody.style.height = '300px !important';
    weixinModalBody.style.position = 'absolute';
    weixinModalBody.style.left = '50%';
    weixinModalBody.style.top = '50%';
    weixinModalBody.style.transform = 'translate(-50%, -50%)';
    weixinModalBody.style.zIndex = '1101';
    weixinModalBody.style.border = '1px solid';
    weixinModalBody.style.display = 'none';
    weixinModalBody.setAttribute('id', 'weixinModalBody');

    weixinCloseModalBtn = document.createElement('button');
    weixinCloseModalBtn.innerHTML = 'x';
    weixinCloseModalBtn.style.position = 'absolute';
    weixinCloseModalBtn.style.top = '4px';
    weixinCloseModalBtn.style.right = '12px';
    weixinCloseModalBtn.style.fontSize = '20px';
    weixinCloseModalBtn.style.background = '#ccc0';
    weixinCloseModalBtn.style.cursor = 'pointer';

    weixinQrcodeDiv = document.createElement('dev');
    weixinQrcodeDiv.style.backgroundColor = '#ffffff';
    weixinQrcodeDiv.style.marginTop = '50px';
    weixinQrcodeDiv.style.height = '240px';
    weixinQrcodeDiv.style.width = '240px';
    weixinQrcodeDiv.style.margin = '32px';
    weixinQrcodeDiv.style.display = 'flex';
    weixinQrcodeDiv.style.alignItems = 'center';
    weixinQrcodeDiv.style.justifyContent = 'center';
    weixinQrcodeDiv.setAttribute('id','urlShareForWeixin');

    weixinModalBody.appendChild(weixinCloseModalBtn);
    weixinModalBody.appendChild(weixinQrcodeDiv);
    document.body.appendChild(weixinPageMask);
    document.body.appendChild(weixinModalBody);

    weixinCloseModalBtn.onclick = function(){
        weixinModalBody.style.display = (weixinModalBody.style.display == "block")? "none" : "block";
        weixinCloseModalBtn.style.display = (weixinCloseModalBtn.style.display == "block")? "none" : "block";
        weixinPageMask.style.visibility = (weixinPageMask.style.visibility == "visible")? "hidden" : "visible";
        document.body.parentNode.style.overflow = 'auto';
    }

}



