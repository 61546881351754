import './typeahead.jquery'
import './jquery_cookie'
import {searchStock, searchTheme, searchIndex} from './search'
import { global, $ ,MstCom} from '../../packs-js/common/_all';
"use strict";

global.getJsonpData = function (data) {
    searchData.data = data;
}

global.getChartJsonpData = function (data) {
    return data
}

function _defineProperty(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {value: value, enumerable: true, configurable: true, writable: true});
    } else {
        obj[key] = value;
    }
    return obj;
}

// Class ****** START
var allDataList;

// todo 暂时写死
const JsonFile = {
    pop_search: 'search_data.json',
    theme_daily: 'theme/daily/%{theme_id}/%{company_code}.json',
    theme_monthly: 'theme/monthly/%{theme_id}/%{company_code}.json',
    index_daily: 'index/daily/%{code}.json',
    index_monthly: 'index/monthly/%{code}.json',
}
const SingletonSearchData = class SingletonSearchData {
    // ES6 does not support this definition
    // data = '';
    constructor(opt = {}) {
        _defineProperty(this, "searchArr", ["stock", "theme"]);

        _defineProperty(this, "option", {
            "stock": {
                "primaryKey": "stock_code",
                "keyArr": ["stock_code", "name"],
                "dataViewFunc": this.get_stock_data_view,
                "limitNum": 10,
                "divStr": "stock_pop_content",
                "externalContent": '.all_pop_content',
                "noDataViewFunc": this.get_common_no_data_view
            },
            "theme": {
                "primaryKey": "id",
                "keyArr": ["id", "name"],
                "dataViewFunc": this.get_theme_data_view,
                "limitNum": 10,
                "divStr": "theme_pop_content",
                "externalContent": '.all_pop_content',
                "noDataViewFunc": this.get_common_no_data_view
            }
        });

        this.data = ''; // //首次使用构造器实例

        if (!SingletonSearchData.instance) {
            this.getData();
            SingletonSearchData.instance = this;
        }
        return SingletonSearchData.instance;
    }

    setOption(opt = {}) {
        this.option = opt;
    }

    getData(opt = {}) {
        return this.get_front_data(opt);
    }

    getSearchArr() {
        return this.searchArr;
    }

    get_front_data(opt = {}) {
        let _this = this;
        // $.getJSON(_this.get_url(opt));
        // #11743 Limit request to json once
        if (_this.data === '' || (_this.data !== '' && Object.keys(_this.data).length === 0)) {
            let url = _this.get_url(opt)
            MstCom.fetchJson(url, {
                cloud: true,
                dataType: 'json',
                method: 'GET',
                error:function(xhr, status, error){
                    console.error('Error fetching data:', status, error);
                },
                success: function (data) {
                    _this.data = data;
                },
                debug: false
            })
        }
        return _this.data
    }

    get_primary_key(key) {
        return this.option[key]["primaryKey"];
    }

    get_key_arr(key) {
        return this.option[key]["keyArr"];
    }

    get_div_str(key) {
        return this.option[key]["divStr"];
    }

    get_limit_num(key) {
        return this.option[key]["limitNum"];
    }

    get_data_view_func(key) {
        return this.option[key]["dataViewFunc"];
    }

    get_no_data_view_func(key) {
        return this.option[key]["noDataViewFunc"];
    }

    get_external_content(key) {
        this.option[key]["externalContent"];
    }

    get_url(opt = {}) {
        let jsonFile = JsonFile.pop_search;
        return jsonFile;
    }

    force_data(opt = {}) {
        // 默认设为true
        return opt["force_data"] || true;
    }

    current_timestamp() {
        return parseInt(new Date().getTime() / 1000);
    }

    check_valid_json_data(data, valid_cols) {
        let _this = this;

        let valid_flg;

        if (_this.check_valid_data(data)) {
            for (let i in data) {
                if (!Array.isArray(valid_cols) || valid_cols.indexOf(i) != -1) {
                    let tmp_valid_flg = _this.check_valid_data(data[i]);

                    if (!tmp_valid_flg) valid_flg = false;
                }
            }
        } else {
            valid_flg = true;
        }

        return valid_flg;
    }

    check_valid_data(data) {
        let valid_flg = !data ? false : true;
        return valid_flg;
    }

    get_company_data_view(data, obj) {
        return searchData.get_common_data_view(data, obj);
    }

    get_stock_data_view(data, obj) {
        let _this = searchData;
        let current_key = obj.currentOption.currentKey;

        let col_arr = _this.get_key_arr(current_key);

        let str = "";
        let data_idx = 0;
        $.each(data, function (i) {
            if (data_idx >= obj.currentOption.limitNum || _this.check_valid_json_data(data[i], col_arr)) return false;
            data_idx++;
            str += '<li><a href="/' + current_key + '/' + data[i].stock_code + '"><span class="mr-2">' + data[i].name + '</span>(' + data[i].stock_code + ')' + '</a></li>';
        });
        return str;
    }

    get_theme_data_view(data, obj) {
        return searchData.get_common_data_view(data, obj);
    }

    set_hot_hist_data_view(data, obj) {
        let str = "";
        let titleObj = {
            'history': '历史',
            'hot': '热门'
        }

        $.each(data, function (idx, item) {
            let data_idx = 0;
            str += '<div class="tt-dataset"><div class="league-name">' + titleObj[idx] + '</div>';
            if (item.length == 0) {
                str += '<p class="tt-none">（暂无数据）</p>';
            }
            $.each(item, function (i) {
                if (data_idx >= obj.limitNum) return false;
                data_idx++;
                str += '<p class="tt-suggestion tt-selectable history">' + item[i] + '</p>';
            })
            str += '</div>';
        });
        $(".tt-dataset-stock").html(str);
        $(".tt-menu").attr("style", "position: absolute; top: 40.5px; left: -37px; z-index: 100; display: grid;")
    }

    get_default_data_view() {
        $('div[id$="_pop_content"]').hide();
        $("#no_data_pop_content").show();
    }

    get_common_no_data_view() {
        $('div[id$="_pop_content"]').hide();
        $("#no_data_pop_content").show();
    }

    get_common_data_view(data, obj) {
        let _this = searchData;
        let current_key = obj.currentOption.currentKey;

        let col_arr = _this.get_key_arr(current_key);

        let str = "";
        let data_idx = 0;
        $.each(data, function (i) {
            if (data_idx >= obj.currentOption.limitNum || _this.check_valid_json_data(data[i], col_arr)) return false;
            data_idx++;
            str += '<li><a href="/' + current_key + '/' + data[i].id + '">' + data[i].name + '</a></li>';
        });
        return str;
    }

};
export const PopSearchInput = class PopSearchInput {
    // ES6 does not support this definition
    constructor(inputObj, opt = {}) {
        this.inputObj = inputObj;
        this.initEvents();
    }

    initEvents() {
        let _this = this;
        var stockMatcher = function (strs) {
            return function findMatches(q, cb) {
                // #11743 Limit request to json once
                if (typeof strs == 'undefined' || (typeof strs != 'undefined' && Object.keys(strs).length == 0)) strs = searchData.getData();
                cb(searchStock(q, strs));
            };
        };
        var themeMatcher = function (strs) {
            return function findMatches(q, cb) {
                // #11743 Limit request to json once
                if (typeof strs == 'undefined' || (typeof strs != 'undefined' && Object.keys(strs).length == 0)) strs = searchData.getData();
                cb(searchTheme(q, strs));
            };
        };
        var indexMatcher = function (strs) {
            return function findMatches(q, cb) {
                // #11743 Limit request to json once
                if (typeof strs == 'undefined' || (typeof strs != 'undefined' && Object.keys(strs).length == 0)) strs = searchData.getData();
                cb(searchIndex(q, strs));
            };
        };

        if ($('#search') !== undefined && $('#search') !== "") {
            let stockIsEmpty = false;
            let themeIsEmpty = false;
            let indexIsEmpty = false;
            $('#search_div .typeahead').typeahead({
                    hint: true,
                    highlight: true,
                    minLength: 1
                },
                {
                    name: 'stock',
                    display: function () {
                        return false;
                    }, //链接点击后输入框不显示相关名称
                    source: stockMatcher(allDataList),
                    templates: {
                        header: '<div class="league-name">公司</div>',
                        suggestion: function (data) {
                            let icons = {
                                cn: '<span class="icon-cn text-[18px] mr-2.5"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span></span>',
                                jp: '<span class="icon-jp text-[18px] mr-2.5"><span class="path1"></span><span class="path2"></span><span class="path3"></span></span>',
                                usa: '<span class="icon-usa text-[18px] mr-2.5"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span><span class="path11"></span></span>',
                                hk: '<span class="icon-hk text-[18px] mr-2.5"><span class="path1"></span><span class="path2"></span></span>'
                            };

                            let suffixMap = {
                                cn: { codes: ['1100', '1101', '1102', '1109', '1200', '1201', '1204', '1209', '2100'], suffixes: { '1100': '.SH', '1101': '.SH', '1102': '.SH', '1109': '.SH', '1200': '.SZ', '1201': '.SZ', '1204': '.SZ', '1209': '.SZ', '2100': '.BJ' } },
                                hk: { codes: ['1300'], suffixes: { '1300': '.HK' } },
                                usa: { codes: ['3100', '3200'], suffixes: { '3100': '.O', '3200': '.N' } },
                                jp: { codes: ['4100', '4300', '4200', '4400'], suffixes: { '4100': '.T', '4300': '.F', '4200': '.O', '4400': '.O' } }
                            };

                            function getFlagAndSuffix(m_code, company_code) {
                                for (const [key, value] of Object.entries(suffixMap)) {
                                    if (value.codes.includes(m_code)) {
                                        return {
                                            flag: icons[key],
                                            company_code: key === 'cn' ? `0086${company_code}` : key === 'hk' ? `0852${company_code}` : company_code,
                                            suffix: value.suffixes[m_code]
                                        };
                                    }
                                }
                                return { flag: '', company_code, suffix: '' };
                            }

                            let { flag, company_code, suffix } = getFlagAndSuffix(data.m_code, data.id);
                            stockIsEmpty = false;
                            return `<p class="!p-0"><a class="w-full px-[13px] py-[7.5px] flex items-center" href="/company/${company_code}/performance">${flag}${data.value}(${data.id}${suffix})</a></p>`;
                        },
                        empty: function () {
                            stockIsEmpty = true;
                            return '<div class="league-name">公司</div><p class="tt-suggestion tt-selectable">（暂无数据）</p>'
                        }
                    }
                },
                {
                    name: 'theme',
                    display: function () {
                        return false;
                    }, //链接点击后输入框不显示相关名称
                    source: themeMatcher(allDataList),
                    templates: {
                        header: '<div class="league-name">概念</div>',
                        suggestion: function (data) {
                            themeIsEmpty = false;
                            return `<p class="grid grid-cols-2"><a href="/theme/${data.id}">` + data.value + '</a></p>';
                        },
                        empty: function () {
                            themeIsEmpty = true;
                            return '<div class="league-name">概念</div><p class="tt-suggestion tt-selectable">（暂无数据）</p>'
                        }
                    }
                },
                {
                    name: 'index',
                    display: function () {
                        return false;
                    }, //链接点击后输入框不显示相关名称
                    source: indexMatcher(allDataList),
                    templates: {
                        header: '<div class="league-name">数据</div>',
                        suggestion: function (data) {
                            indexIsEmpty = false;
                            return `<p class="grid grid-cols-2"><a href="">` + data.value + '</a></p>';
                        },
                        empty: function () {
                            indexIsEmpty = true;
                            return '<div class="league-name">指数</div><p class="tt-suggestion tt-selectable">（暂无数据）</p>'
                        }
                    }
                });
            // 创建固定链接所在的div并隐藏
            var fixedLinkDiv = $('<div class="fixed-link-container"></div>').hide();
            var fixedLink = $('<a href="#" class="fixed-link" id="pop_search_btn">查看全部搜索结果</a>');
            fixedLinkDiv.append(fixedLink);
            $('.tt-menu').append(fixedLinkDiv);

            $('#search').on('input', function () {
                if (!stockIsEmpty || !themeIsEmpty || !indexIsEmpty) {
                    $('.fixed-link-container').show();
                } else {
                    $('.fixed-link-container').hide();
                }
            })

            $('.fixed-link').on('click', function (event) {
                if ($('#search').val().trim() === '') {
                    event.preventDefault(); // 阻止默认行为
                } else {
                    var formObj = $('#search_form');
                    $('<input>').attr({
                        type: 'hidden',
                        name: 'authenticity_token',
                        value: $("meta[name=csrf-token]").attr("content")
                    }).appendTo(formObj);
                    formObj.submit();
                }
            });

            // 当页面加载完成时运行代码
            $(document).ready(function () {
                var inputValue = localStorage.getItem('searchInputValue');
                if (inputValue && inputValue.trim() !== '') {
                    $('.tt-menu .fixed-link-container').show();
                }

                // 监听搜索框内容变化事件
                $('#search').on('input focus', function () {
                    var inputValue = $(this).val().trim();
                    if (inputValue === '') {
                        searchData.set_hot_hist_data_view(getSearchHistoryData(), {
                            currentKey: "history",
                            limitNum: 5
                        });
                        $('.fixed-link-container').hide();
                    } else {
                        // $('.tt-menu .fixed-link-container').show();
                        localStorage.setItem('searchInputValue', inputValue);
                    }
                });
            });

            // 在页面加载完成后检查搜索框的值是否为空，并根据需要显示固定链接
            var checkSearchBoxValue = function () {
                var inputValue = $('#search').val().trim();
                if (inputValue !== '') {
                    $('.tt-menu .fixed-link-container').show();
                    localStorage.setItem('searchInputValue', inputValue);
                }
            };
            // 页面加载完成时运行检查搜索框值的函数
            $(document).ready(checkSearchBoxValue);
        }
    }
};

export const updateSearchHistoryData = function (type, search_val) {
    let val = $.trim(search_val);
    let histData = getSearchHistoryData();
    if (Object.keys(histData).indexOf(type) === -1) histData[type] = []

    if (val.length > 0 && histData[type].indexOf(val) == -1) {
        histData[type].unshift(val);
        $.cookie('search_history', JSON.stringify(histData), {
            path: '/'
        });
    }
};

export const getSearchHistoryData = function () {
    if ($.cookie('search_history')) {
        return JSON.parse($.cookie('search_history'));
    } else {
        return {'history': [], 'hot': []};
    }
};

export const searchData = new SingletonSearchData();
